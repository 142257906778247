import React from "react";
import {DefaultLayout} from "../../organisms/DefaultLayout";
import * as styles from "../../styles/careers/404.module.scss"

export default () => <DefaultLayout>
  <div className={styles.policy}>
    <h2>Site Policy</h2>
    <h3>サイトご利用規約</h3>
    <p>
      １ ご利用にあたって
      1. このウェブサイト (https://suprieve.com/、以下「本サイト」といいます。）のご利用にあたっては、事前にこの利用規約（以下「本規約」といいます。）をご一読頂き、同意された場合にのみご利用下さい。
      2. Suprieve株式会社（以下「当社」といいます。）は、予告なく本規約の内容を変更する場合がありますので、あらかじめご了承下さい。

      ダミー

      ●年●月●日制定
    </p>
  </div>

</DefaultLayout>;